<template>
  <v-app class="background">
    <Toolbar />
    <v-layout row wrap>
      <v-flex xs12 lg12>
        <v-carousel
          :continuous="false"
          :cycle="cycle"
          :show-arrows="false"
          hide-delimiter-background
          delimiter-icon="mdi-minus"
        >
          <v-carousel-item
            v-for="(item, i) in itemsImage"
            :key="i"
            :src="item.src"
          ></v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
    <br>
    <v-container class="pt-16">
      <v-layout row wrap justify-center align-content-center>
        <v-flex xs11 lg12>
          <v-tabs v-model="tab"  color="#9c7656"      :vertical="$vuetify.breakpoint.smAndDown">
            <v-tab v-for="item in itemsTab" :key="item.tab"> {{ item.tab }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <!-- -----------------------------tab 1----------------------------- -->
            <v-tab-item>
              <Restismore />
            </v-tab-item>
            <!-- -----------------------------tab 2----------------------------- -->
            <v-tab-item>
              <Gelato />
            </v-tab-item>
            <!-- -----------------------------tab 3----------------------------- -->
            <v-tab-item>
              <Dessert />
            </v-tab-item>
            <!-- -----------------------------tab 4----------------------------- -->
            <v-tab-item>
              <Beverage />
            </v-tab-item>
            <!-- -----------------------------tab 5----------------------------- -->
            <v-tab-item>
              <Knowledge />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-container>
    <Footer />
  </v-app>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue'
import Footer from '@/components/Footer.vue'
import Restismore from '@/views/pages/DetailBlog/Restismore.vue'
import Gelato from '@/views/pages/DetailBlog/Gelato.vue'
import Dessert from '@/views/pages/DetailBlog/Dessert.vue'
import Beverage from '@/views/pages/DetailBlog/Beverage.vue'
import Knowledge from '@/views/pages/DetailBlog/Knowledge.vue'

export default {
  components: {
    Toolbar,
    Footer,
    Restismore,
    Gelato,
    Dessert,
    Beverage,
    Knowledge
  },
  data: () => ({
    cycle: false,
    tab: null,
    itemsImage: [
      {
        src: require('@/assets/img/Blog/banner1.jpg')
      },
      {
        src: require('@/assets/img/Blog/banner2.jpg')
      },
      {
        src: require('@/assets/img/Blog/banner3.jpg')
      },
      {
        src: require('@/assets/img/Blog/banner4.jpg')
      },
      {
        src: require('@/assets/img/Blog/banner5.jpg')
      }
    ],
    itemsTab: [
      { tab: 'Rest is more' },
      { tab: 'Gelato' },
      { tab: 'Dessert' },
      { tab: 'Beverage' },
      { tab: 'Knowledge' }
    ]
  })
}
</script>
<style scoped>
.font-color {
  font-size: 60px;
  color: #9c7656;
}
.background {
  background-color: #EDEDE7;
}
</style>
