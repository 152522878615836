<template>
  <v-app class="background">
    <v-layout row wrap justify-center align-center pt-16>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400" height="590">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_9.jpg"
          >
          </v-img>

          <v-card-text class=" brown--text text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3">
              แรงบันดาลใจของ <b>Artisan Strawberry Cheesecake</b>  เกิดจากความชอบทานชีสทาร์ตสตอเบอร์รี่
              เราจึงได้ไอเดียมาทำเจลาโต้รสนี้ ที่เน้นรสของครีมชีสแบบเน้นๆ ขั้นตอนการทำ
              คือการทำเบสเจลาโต้ที่เราใช้ครีมชีส มา Blend <br> กับวิปปิ้งครีม ทำให้ได้เบสครีมชีสที่นุ่ม
              ละมุน จากนั้นนำสตอเบอร์รี่แยมโฮมเมดสูตรเฉพาะของทางร้าน มา swirl
              ลงไป(เป็นคนละตัวกับแยมที่ทาในสโคนนะคะ) ซึ่งเป็นแยมที่ออกรสหวานหน่อยๆ
              จึงตัดรสเปรี้ยวกับครีมชีสได้อย่างลงตัว และสิ่งที่ขาดไม่ได้เลยคือ<b>”ครัมเบิ้ล”</b>
              ที่เปรียบเหมือน Crust ของตัวทาร์ต ที่เราใช้แป้งอัลมอนด์มาผสมกับเนย
              รสชาติจะออกรสเค็มนิดๆ เมื่อนำมาทานคู่กันกับตัวเบสชีส และสตอเบอร์รี่แยม
              จึงได้รสชาติที่เหมือนกับทาน ชีสทาร์ตสตอเบอร์รี่จริงๆ
            </div>

            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="grey--text">2/02/2022</div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_8.jpg"
          >
          </v-img>
          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3  brown--text">
              <b>#BlueberryYogurtGelato </b> จริงๆชื่อฟังดูเหมือนง่ายและทำได้ไม่ยากนะ เพราะวัตถุดิบหลัก
              ก็คือ “บลูเบอร์รี่” กับ “โยเกิร์ต” แต่พอเราได้ลงมือทำจริงๆ
              กลับกลายเป็นว่าดีเทลหลากหลายอย่างเยอะมาก เริ่มตั้งแต่ #โยเกิร์ต ที่มีมากกว่า 10
              รสชาติจากหลากหลายยี่ห้อ ตอนเริ่มทำเราซื้อโยเกิร์ตทุกยี่ห้อมาลองชิม
              ลองเทสกันเลยทีเดียวว่า เพราะรสชาติจะต้องไม่ออกหวาน หรือเปรี้ยวจนเกินไป
              ไม่ฉะนั้นรสชาติจะไม่บาลานซ์และออกไปทานรสของนมเปรี้ยวเสียมากกว่า
              ซึ่งเราไม่ต้องการแบบนั้นเลย สุดท้ายเราจึงเลือกเป็นโยเกิร์ตของฝรั่งเศส
              ที่ถึงแม้ราคาจะค่อนข้างสูง แต่เราต้องคำนึงถึงหัวใจหลักของเจลาโต้ของร้านเรา
              นั่นก็คือรสชาติต้องมาก่อนเสมอ ต่อมาเป็น
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showBlueberry = !showBlueberry">
              <v-icon>{{ showBlueberry ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showBlueberry">
              <v-divider></v-divider>

              <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine" class="text-left  brown--text">
                <b>#บลูเบอรี่</b> - ตัวบลูเบอร์รี่เองก็มีหลายเกรด ที่ส่งผลต่อคุณภาพ รสชาติที่แตกต่างกัน
                หากเป็นเกรดที่ต่ำก็จะทำให้บลูเบอรี่มีรสฝาด เมื่อนำมาผสมกับโยเกิร์ตก็จะไม่เข้ากัน
                และถ้าหากเป็นบลูเบอร์รี่สด รสชาติของบลูเบอรี่อาจจะไม่เสถียร บางครั้งเปรี้ยว
                บางครั้งหวาน จนพลอยดึงรสชาติให้ให้ผิดเพี้ยนไปจากที่เราตั้งใจอีกด้วย
                ดังนั้นเราจึงตัดสินใจใช้บลูเบอรี่เพียวเร่จากฝรั่งเศส แน่นอนว่าไม่มีการแต่งสี
                แต่งกลิ่น หรือวัตถุกันเสีย อย่างสุดท้ายคือ #ซอสบลูเบอร์รี่ ที่ราดอยู่ด้านบน
                เป็นซอสบลูเบอร์รี่โฮมเมดที่เราเคี่ยวเอง กว่าจะได้เจลาโต้แต่ละรสชาติ
                ต้องบอกเลยค่ะว่าไม่ง่ายจริงๆเพราะดีเทลของวัตถุดิบแต่ละตัวเยอะมากๆ
                แต่เราจะยังคงพัฒนาเจลาโต้รสชาติใหม่ๆต่อไป ติดตามชิมกันต่อได้นะคะ
                <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pt-3 grey--text">27/02/2022</div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400" height="590">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_5.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3  brown--text">
              <b> Matcha Gelato</b> เราเลือกใช้ผงมัทฉะนำเข้าจากญี่ปุ่น <br> ของเมืองเกียวโต
              เพราะเราเชื่อเสมอว่าวัตถุดิบที่ดี จะสามารถรังสรรค์อาหารออกมาให้ดีได้
              หลักการทำเจลาโต้ของเราก็เช่นกัน ด้วยเหตุนี้ Matcha Gelato
              ของที่ร้านจึงได้รสอูมามิจากผงมัทฉะแท้ๆ ทำให้เมื่อนำมาผสมกับเบสนม
              จึงได้รสชาติของมัทฉะที่ละมุนขึ้น แต่กลิ่นและความเข้มของชายังคงอยู่อย่างเป็นเอกลักษณ์
              ไม่จางหาย
            </div>

            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="grey--text">30/11/2021</div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center align-center pb-16>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400" height="590">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_7.jpg"
          >
          </v-img>

          <v-card-text
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="text--primary text-left"
          >
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3  brown--text">
              <b>CARAMEL POPCORN INSPIRED CRUNCHY</b>  CARAMELLOW กลิ่นหอมจากน้ำตาลไหม้อ่อนๆ จากความ
              Caramelized ของคาราเมล เนื้อสัมผัสมีความ’ครั้นซี่’จากถั่วแมคคาเดเมีย on top ด้วย
              macadamia pralines ทั้งหมดนี้ทำให้เราได้รสชาติที่โดดเด่น และน่าหลงใหล
              เพราะนอกจากเนื้อสัมผัสเจลาโต้จะมีความเนียน นุ่มแล้ว
              เพิ่มเติมคือมีเนื้อสัมผัสของแมคคาเดเมียให้ได้เคี้ยว ตรงตามคอนเซ็ปท์ของ rest is more
            </div>

            <div class="grey--text">3/01/2022</div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_6.jpg"
          >
          </v-img>
          <v-card-text
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="text--primary text-left"
          >
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3  brown--text">
              <b>“Pineapple Passion Fruit Sorbetto”</b> ที่ถึงแม้ชื่อจะดูเรียบง่าย แต่องค์ประกอบต่างๆ
              และดีเทลเรียกว่าจัดเต็ม ตามแบบฉบับ rest is more เบสซอร์เบทโต้ คือเสาวรสและสับปะรด
              ซึ่งเราใช้ผลไม้สดมารังสรรค์จนได้รสหวานอมเปรี้ยว ซึ่งกลิ่นของเสาวรสตลบอบอวลมากกกก <br>
              แต่ความพิเศษอยู่ตรงที่ <b>’ซอสเสาวรส’</b>  ที่เราใช้เทคนิค <br> fruit compote ในการทำ!
              โดยการนำเสาวรสไปเคี่ยวกับน้ำตาลด้วยไฟอ่อนๆ
              ค่อยๆให้ความหวานของน้ำตาลไปผสมกับความเปรี้ยวของเสาวรสอย่างช้าๆ
              โดยที่เสาวรสยังคงรูปและเนื้อสัมผัสอยู่ เราจึงต้องคอยจับตามองน้องเอาไว้ <br> ไม่ให้เละ
              จนกลายเป็นแยมไปเสียก่อน ละเอียดอ่อนมากๆ เอกลักษณ์ของเสาวรสคือตัวเมล็ด
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showPineapple = !showPineapple">
              <v-icon>{{ showPineapple ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showPineapple">
              <v-divider></v-divider>

              <v-card-text data-aos="fade-up" data-aos-easing="ease-in-sine" class="text-left  brown--text">
                 ที่สามารถทานได้ เมล็ดของเสาวรสที่ได้จากการ compote
                จึงเหมือนเป็นเยลลี่ใสๆเคลือบที่เมล็ด พอได้ความหนึบเล็กน้อย
                แต่ความกรุบของเมล็ดยังคงอยู่ เบสเรียบร้อย ตัวซอสเสาวรสลงตัว ก็ได้เวลามามิกซ์ด้วยการ
                swirl ซอสลงไปในเบส เมื่อทานแล้วจะรู้สึกสดชื่น แถมยังมี texture ของเมล็ดเข้าไปอีก
                เอนจอยแน่นอนค่ะ
                <div class="grey--text pt-3">29/08/2021</div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400" height="590">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_4.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3  brown--text">
              กลิ่นวานิลลาอันเป็นเอกลักษณ์ ซึ่งทางร้านได้เริ่มใช้ <br> <b>#ฝักวานิลลาจากอูกันด้า</b>
              แทนฝักวานิลลาจากมาดากัสการ์เดิม เพื่อเปิดประสบการณ์ให้แก่ผู้หลงใหลในรสและกลิ่นวานิลลา
              ซึ่งสิ่งที่เห็นได้ชัดคือ ฝักวานิลลาจากอูกันด้าจะมีขนาดอวบใหญ่ มีสีดำเงางาม
              ให้กลิ่นที่มีมิติมากขึ้น โดยจะมีกลิ่น woody และ fruity เล็กน้อย
              จึงทำให้เจลาโต้รสวานิลลาของทางร้าน มีกลิ่นหอมหวานที่ครีมมี่มากขึ้นกว่าแต่ก่อน <br>
              มาลองชิมพรีเมียมวานิลลาเจลาโต้กันได้นะคะ
            </div>

            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="grey--text">16/11/2021</div>
          </v-card-text>
        </v-card>
      </v-flex>
       <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400"  height="590">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_10.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3  brown--text">
              <b>“กว่าจะได้เจลาโต้สักรสหนึ่งไม่ใช่เรื่องง่าย”</b>  เนื่องด้วยเจ้าแสน (ชื่อเครื่องปั่นเจลาโต้
              FRIGOMAT G10) จะรองรับของเหลว 1.5 กิโลขึ้นไป หากน้อยกว่านั้น
              เจ้าแสนก็จะไม่ยอมเดินเครื่อง ซึ่งแตกต่างจากการทำเบเกอรี่
              ที่เราสามารถเทสอัตราส่วนน้อยๆไปก่อนได้ พอเวิร์ค ก็ค่อยคูณสูตรเพิ่มปริมาณเข้าไป
              แต่เจลาโต้คือไม่ใช่เลย ช่วงไหนเทสสูตร ก็คือเจลาโต้เต็มตู้
              และบางครั้งเราก็จำเป็นต้องทิ้งวัตถุดิบไปหลายกิโล ต้องขอบคุณทุกคนที่พยายามต่อไป
              ไม่ยอมแพ้ง่าย จนได้ผลลัพธ์ที่ถึงแม้จะช้า’แต่ชัวร์’ คำว่า’ชัวร์’ในที่นี้ เราหมายถึง
              ได้รสชาติที่เราถูกใจ และได้เนื้อสัมผัสที่ถูกต้อง คือเป็นรสชาติที่เข้าใจง่าย
              เมื่อทานแล้วบ่งบอกถึงรสนั้นจริงๆ แต่ก็ยังแอบซ่อนลูกเล่นเล็กๆผ่านทางเนื้อสัมผัส
              เป็นการเพิ่มมิติของรสชาตินั้นๆขึ้นมา
            </div>

            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="grey--text">28/08/2021</div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    showBlueberry: false,
    showPineapple: false
  })
}
</script>
<style scoped>
.background {
  background-color: #EDEDE7;
}
</style>
