<template>
  <v-app class="background">
    <v-layout row wrap justify-center align-center pt-16 pb-16>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_21.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3 brown--text">
              ครีมชีส หนึ่งในวัตถุดิบสำคัญของเจลาโต้รส strawberry cheesecake และ artisan strawberry
              ครีมชีสจัดเป็น fresh cheese ชนิดหนึ่ง ที่ทำมาจากครีม หรือ นมและครีมผสมกัน
              โดนไม่ผ่านการหมักหรือบ่มด้วยความร้อน เพื่อคงรสชาติและกลิ่นให้เป็นธรรมชาติที่สุด
              ซึ่งครีมชีสแต่ละยี่ห้อก็จะให้รสชาติที่แตกต่างกันไป
              การเลือกใช้ก็ขึ้นอยู่กับว่าเราจะนำมาทำอะไร อย่างเจลาโต้รส strawberry cheesecake และ
              artisan strawberry เราเลือกใช้ครีมชีสที่ออกรสเปรี้ยวนำ มีเอกลักษณ์ความเป็นชีสที่ชัดเจน
              เนื้อผัสสัมเนียนและแน่น เมื่อนำมามิกซ์รวมกับแยมสตอเบอรี่ที่มีรสหวาน
              จึงได้รสเปรี้ยวหวานที่ลงตัว การเก็บรักษา ครีมชีส ควรเก็บในตู้เย็นอุณหภูมิ 2-7 องศา
              และไม่ควรนำเข้าช่องฟรีส เนื่องจากครีมชีสเมื่อโดนความเย็นจัดจะแตกตัว
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showStrawberry = !showStrawberry">
              <v-icon>{{ showStrawberry ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showStrawberry">
              <v-divider></v-divider>

              <v-card-text
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                class="text-left brown--text"
              >
                เวลานำมาใช้เนื้อจะไม่เนียน เป็นเม็ดๆ และก่อนนำมาใช้ควรทิ้งไว้ที่อุณหภูมิห้อง
                จะช่วยให้ผสมหรือตีครีมชีสได้ง่ายขึ้นค่ะ

                <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pt-3 grey--text">
                  16/10/2021
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_22.jpg"
          >
          </v-img>
          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3 brown--text">
              เราว่าเสน่ห์ของเจลาโต้ หรือไอศกรีม คือการที่เราสามารถนำจุดเด่นของวัตถุดิบแต่ละชนิด
              มาแปรรูปเป็นไอศกรีมได้ การได้เฟ้นหาวัตถุดิบต่างๆมารังสรรค์
              ค้นคว้าหาข้อมูลวัตถุดิบแต่ละชนิด และทดลองทำขึ้นมาจริงๆ เสมือนเป็นห้องวิทยาศาสตร์เล็กๆ
              แต่จุดเริ่มต้นของการค้นคว้าและทดลองเหล่านี้ ล้วนเกิดมาจาก’การจินตนาการรสชาติ’
              เมื่อองค์ประกอบของวัตถุดิบต่างๆถูกนำมาผสมรวมกัน จนเกิดเป็นเจลาโต้รสชาติหนึ่งได้ <br />
              <b>#ToffeeCoffeeGelato</b>  คือหนึ่งตัวอย่างของจินตนาการนั้น ความหวานโดดเด่นของ #ซอสท็อปฟี่
              ที่ถูกทำขึ้นมาจาก น้ำตาล เนยและน้ำ ได้มาถูกหลอมรวมกับกาแฟ  <br> <b>#Arabica </b> ซึ่งเป็นเบสหลัก
              แต่ก็ยังมีองค์ประกอบรองอย่างอื่น เช่น นม ครีม อยู่ด้วยเช่นกัน ส่วนกิมมิคที่เรานำ
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showToffeeCoffee = !showToffeeCoffee">
              <v-icon>{{ showToffeeCoffee ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showToffeeCoffee">
              <v-divider></v-divider>

              <v-card-text
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                class="text-left brown--text"
              >
               <b>#อัลมอนด์ </b> มาปิดท้าย เพราะรสและกลิ่นของอัลมอนด์สามารถเข้ากับกาแฟได้เป็นอย่างดี
                อีกทั้งยังเป็นการเพิ่มความซับซ้อนของเนื้อสัมผัสอีกด้วย ทั้งหมดนี้ไม่ใช่เรื่องบังเอิญ
                ทุกอย่างผ่านความคิด การจินตนาการ การศึกษา และการทดลองมาจนมั่นใจ
                ว่าส่วนผสมทุกอย่างสามารถเข้ากันได้ แล้วคุณล่ะคะ มองเห็นเสน่ห์ของเจลาโต้ในรูปแบบไหน?

                <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pt-3 grey--text">
                  30/09/2021
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_23.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3 brown--text">
              <b>#เกร็ดความรู้ </b> <br />
              ความท้าทายของการทำ #ซอร์เบทโต้ คือการที่เนื้อไอศกรีมอาจจะเกิดเกล็ดน้ำแข็งได้ง่าย
              เพราะไม่มีโปรตีนและไขมัน (จากนมและครีม) เป็นตัวจับน้ำ ยิ่งไอศกรีมมีน้ำมากเท่าไหร่
              โอกาสเกิดเกล็ดน้ำแข็งก็ยิ่งง่ายมากขึ้นเท่านั้น
              ดังนั้นเราจึงต้องควบคุมเกล็ดน้ำแข็งให้เล็กที่สุด <br />
              ซึ่งขั้นตอนที่สำคัญ อย่างแรกคือ <br />
              <div class="pt-2">
                1.การปั่นไอศกรีม #Churning ซึ่งระยะเวลาในการปั่นสำคัญมากๆ
              เพราะถ้าเราสามารถปั่นไอศกรีมให้เซตตัวได้เร็ว
              โอกาสที่น้ำจะรวมตัวกันจนเกิดเป็นเกล็ดน้ำแข็งก็ยิ่งลดลง ซึ่งเครื่องทำไอศกรีมที่ดี
              จะสามารถปั่นไอศกรีมให้เสร็จได้ภายใน 8-15 นาที และทำให้เนื้อไอศกรีมเนียน <br>
              (แต่นั่นก็มาพร้อมราคาที่โหดมากเช่นกัน)
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showChurning = !showChurning">
              <v-icon>{{ showChurning ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showChurning">
              <v-divider></v-divider>

              <v-card-text
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                class="text-left brown--text"
              >
                2.การแช่แข็ง #Freezing อุณหภูมิที่แช่แข็งควรมีความเสถียร และต่ำมากพอ
                เพราะถ้าอุณหภูมิไม่คงที่ โอกาสที่น้ำจะรวมตัวกันจะง่ายขึ้น
                ทำให้เกล็ดน้ำแข็งมีขนาดใหญ่ ลองสังเกตง่ายๆคือเวลาที่เราแช่ไอศกรีมในตู้เย็น
                จะเห็นเกล็ดน้ำแข็งชัดมาก (เกล็ดน้ำแข็งมีขนาดใหญ่) ด้วยอุณหภูมิที่ไม่ได้เย็นจัด
                และอุณหภูมิที่เหวี่ยง จากการที่เราเปิดปิดตู้เย็นบ่อยๆ ฉะนั้น การทำเจลาโต้ให้มีคุณภาพ
                วัตถุดิบอย่างเดียว อาจจะไม่เพียงพอ เราจึงต้องคิดคำนึงหลายๆอย่างไปพร้อมๆกัน
                เพื่อรังสรรค์เจลาโต้ที่ดีให้แก่ลูกค้า <br> rest is more ทุกท่านค่ะ

                <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pt-3 grey--text">
                  ขอขอบคุณความรู้จากเชฟบอล 10010 Bar
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
         <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_24.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3 brown--text">
              ขั้นตอนการทำเจลาโต้ เริ่มตั้งแต่ Cooking ไปจนถึงการ Freezing
              ปัจจัยสำคัญอย่างหนึ่งที่ส่งผลต่อคุณภาพของเจลาโต้ คือเรื่องของ <b>“อุณหภูมิ”</b>  <br />
              โดยเริ่มจากการ #Cooking ซึ่งเราจะนำส่วนผสมหลักที่เป็นของเหลว(นม,น้ำ)
              และของแห้ง(น้ำตาล) มาผสมรวมกันเพื่อเข้าสู่กระบวนการ #Pasteurization
              ด้วยการใช้เครื่องเทอโมมิกซ์ปั่นส่วนผสมให้เข้ากัน ด้วยอุณหภูมิสูงถึง 85 องศา
              เพื่อเป็นการทำลายจุลินทรีย์ที่อาจก่อให้เกิดโรค แล้วจึงนำมา #Cooling อย่างรวดเร็ว
              ที่อุณหภูมิ ต่ำกว่า 5 องศา <br />
             <b> **เทอโมมิกซ์ (Thermomix)</b>  คือเครื่องทำอาหาร
              ที่เราสามารถใช้เจ้าเครื่องนี้ในการปั่นวัตถุดิบด้วยความเร็ว เวลา
              และอุณหภูมิที่ต้องการได้
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showCooking = !showCooking">
              <v-icon>{{ showCooking ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showCooking">
              <v-divider></v-divider>

              <v-card-text
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                class="text-left brown--text"
              >
                หลังจากผ่านกระบวนการ Cooling ต่อไปคือการ #Aging ที่เราจะนำส่วนผสมที่ได้ไปบ่ม ประมาณ
                4 - 24 ชั่วโมง ยกตัวอย่างเช่น Premium Vanilla Gelato เรานำฝักวานิลลาบ่มไปพร้อมกัน
                เพื่อดึงกลิ่นวานิลลาให้หอมมากขึ้น <br />

                เมื่อครบเวลาที่กำหนดเราจึงทำการ #Churning หรือการนำส่วนผสมไปปั่นในเครื่องทำไอศกรีม
                จากนั้นจึงนำไป #Freezing ต่อทันทีที่อุณหภูมิติดลบ
                เพื่อลดการเกิดเกล็ดน้ำแข็งของเนื้อไอศกรีม <br />
                เห็นแล้วใช่มั้ยคะว่า ทุกขั้นตอนในการทำ วัตถุดิบ อุปกรณ์ หรือแม้แต่อุณหภูมิ
                ทุกอย่างล้วนส่งผลต่อคุณภาพของเจลาโต้ทั้งสิ้น และทั้งหมดเป็นสิ่งที่เราต้องควบคุม
                ถึงแม้จะเป็นเจลาโต้โฮมเมด แต่เราก็ต้องมีมาตรฐานในการทำ เพื่อคุณทุกคนค่ะ
                <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pt-3 grey--text">
                  21/11/2021
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="230px"
            src="@/assets/img/Blog/IMG_25.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3 brown--text">
              <b>Premium Vanilla Gelato</b>  กลิ่นหวานๆแบบละมุนๆ ที่ทุกคนได้ลิ้มลองกันอยู่ตอนนี้
              เกิดจากฝักวานิลลาแท้ ที่เรานำมา infuse กับตัวเบสเจลาโต้ แน่นอนว่าการจะได้ความหอมนี้มา
              ไม่ใช่เพียงแค่การเลือกใช้วัตถุดิบที่สำคัญ แต่การเก็บรักษาอย่างถูกวิธี
              ก็จำเป็นไม่แพ้กัน เพราะหากเราเก็บรักษาฝักวานิลลาไม่ถูกต้อง
              ความชื้นของฝักวานิลลาที่ถือเป็นหัวใจหลัก ก็อาจสูญเสียไปได้ <br />
              ทางร้านจึงให้ความสำคัญกับการเก็บรักษาวัตถุดิบเป็นอย่างมาก
              โดยฝักวานิลลาของเราจะถูกจัดเก็บในภาชนะที่ปิดมิดชิด
              เพื่อป้องกันไม่ให้ฝักวานิลลาแห้งจนเกินไป จากการสัมผัสอากาศ
              เป็นการกักเก็บความชุ่มชื้นของตัวฝักไว้ เพื่อคงกลิ่นให้มีมิติของดอกไม้อยู่
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showVanilla = !showVanilla">
              <v-icon>{{ showVanilla ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showVanilla">
              <v-divider></v-divider>

              <v-card-text
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                class="text-left brown--text"
              >
                ฝักวานิลลาไม่ควรโดนแสงแดด เพราะจะทำให้ตัวฝักแห้ง และเปลี่ยนสี
                แต่เราก็ไม่ควรนำไปแช่เย็นเช่นกัน
                เหตุผลเพราะความเย็นจะทำให้น้ำมันธรรมชาติที่อยู่บนผิวฝักวานิลลาจับตัวกันเป็นก้อน
                จนเกิดจุดขาวๆที่บริเวณตัวฝัก
                สุดท้ายฝักวานิลลาราคาแพงก็ถูกความเย็นดูดความชื้นไปหมดเสียแล้ว <br />
                ดังนั้นเพื่อรักษากลิ่นของฝักวานิลลาเอาไว้ ควรเก็บที่อุณหภูมิห้องและเก็บให้มิดชิด
                ไม่ปนกับวัตถุดิบชนิดอื่นๆ หากใครสามารถเก็บแบบสูญญากาศได้
                ก็มั่นใจได้ว่าไม่มีอากาศเข้า ก็ดูจะดีที่สุด

                <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="grey--text pt-3">
                  23/08/2021
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    showStrawberry: false,
    showToffeeCoffee: false,
    showChurning: false,
    showCooking: false,
    showVanilla: false
  })
}
</script>
<style scoped>
.background {
  background-color: #EDEDE7;
}
</style>
