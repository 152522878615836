<template>
  <v-app class="background">
    <v-layout row wrap justify-center align-center pt-16 pb-16>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="240px"
            src="@/assets/img/Blog/IMG_11.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3 brown--text">
              <b>สโคน (Scone)</b> ขนมอีกหนึ่งอย่างที่เราได้ลองทานแล้วชอบมากๆ เพราะเป็นขนมที่ทานง่าย
              รสชาติไม่ซับซ้อน แถมทานแล้วอยู่ท้องอีกด้วย จุดเริ่มต้นมาจาก
              เราได้ไปลองทานสโคนหลายๆร้านในกรุงเทพ อาทิเช่น 1823 Ronnefeld, Harrods, TWG
              สายแป้งอย่างเราจึงโดนตกไปโดยปริยาย แน่นอนค่ะว่า เวลาที่เราชอบอะไร
              เราก็อยากแบ่งปันให้คนอื่นได้ลองบ้าง เรียกว่าเป็นการป้ายยาค่ะ
              ที่ร้านเลยมีขนมหรือเบเกอรี่ที่ค่อยข้างหลากหลายพอสมควร
              เพราะเราอยากให้คนหาดใหญ่ได้ลิ้มลองทั้งหมดเลย ซึ่งเท็กเจอร์สโคนของที่ร้าน
              คือเป็นเนื้อที่นุ่ม ไม่แน่นหรือร่วนจนเกินไป เหมือนเนื้อเค้ก
              แต่มีความกรอบเล็กน้อยบนผิวสัมผัสด้านนอกเหมือนบิสกิต
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showScone = !showScone">
              <v-icon>{{ showScone ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showScone">
              <v-divider></v-divider>

              <v-card-text
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
                class="text-left brown--text"
              >
                ตรงกับความชอบของตัวเราเอง แต่จุดเด่นที่สุดของสโคนร้านเราคือ
                <b>’รสชาติ’</b> ซึ่งเราเลือกใช้เนย Elle et vire ของฝรั่งเศส
                ทำให้ได้รสชาติเนยที่เด่นชัด และหอมมากๆ ยิ่งตอนอบเสร็จใหม่ๆ ก็คือหอมฟุ้งไปทั่ว
                <div class="grey--text pt-3">10/01/2022</div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400" height="580">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="240px"
            src="@/assets/img/Blog/IMG_12.jpg"
          >
          </v-img>
          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3 brown--text">
              โดยปกติแล้วเวลาที่เราทานสโคน เรามักจะทานคู่กับชาร้อนๆ ตามแบบฉบับ
              <b>Afternoon tea set</b> ที่มักจะมีสโคน และขนมอื่นๆ มาให้ทานแบบครบเซต
              แต่หากใครอยากทานแต่สโคนอย่างเดียว เราก็ไม่ว่ากันนะคะ ขั้นตอนการทาน <br />
              <div class="pt-2">
                1. อุ่นสโคนด้วยเตาอบ 2-3 นาทีที่อุณหภูมิ 180 องศา <br />
                เพื่อให้กรอบนอกนุ่มใน <br />
                2.หลังจากอุ่นแล้ว นำมีดมาผ่ากลาง แล้วทาแยม ตามด้วยครีม หรือใครจะทาครีม
                แล้วตามด้วยแยม ก็ได้ค่ะ <br />
                (ไม่ทะเลาะกันนะคะ) <br />
                3.จากนั้นก็ทานได้เลยค่า ไม่ต้องนำมาประกบกันนะคะ
              </div>
            </div>
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="grey--text">
              12/01/2022
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400" height="580">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="240px"
            src="@/assets/img/Blog/IMG_13.jpg"
          >
          </v-img>

          <v-card-text class="text--primary text-left">
            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="pb-3 brown--text">
              สูตร <b>Fudge Brownie</b>  ของที่ร้าน ที่เราพัฒนาสูตรเองจนได้รสชาติอันเป็นเอกลักษณ์
              โดยเราใช้ความหวานที่ได้จาก Dark Chocolate 70% ทำให้เราสามารถลดน้ำตาล
              จนได้ความหวานที่เหมาะสม พร้อมรสดาร์กช็อกโกแลตที่เข้มข้น จึงทำให้ Fudge Brownie
              กลายเป็นสินค้าขายดีของที่ร้านขึ้นมาทันที ใครที่เคยทานบราวนี่อื่นๆ
              แล้วรู้สึกหวานจนแสบคอ ลองเปิดใจอีกสักครั้ง ลองทานบราวนี่ของร้านเราดูสิคะ
            </div>

            <div data-aos="fade-up" data-aos-easing="ease-in-sine" class="grey--text">
              9/12/2021
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
        <v-flex xs10 lg4 pa-4>
        <v-card class="mx-auto" max-width="400">
          <v-img
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            class="white--text align-end"
            height="240px"
            src="@/assets/img/Blog/IMG_14.jpg"
          >
          </v-img>
          <v-card-text class="text--primary text-left">
            <div class="pb-3 brown--text" data-aos="fade-up" data-aos-easing="ease-in-sine">
              สิ่งที่สำคัญที่สุดในการทำขนมที่ไม่ควรมองข้าม <br> คือเรื่องของ”แป้ง” ซึ่งทั้งคุกกี้ <br>
              <b>#ChewyChocChip</b>  และ <b>#DuoChoc</b>  <br> เราเลือกใช้แป้งสาลีนำเข้าจากฝรั่งเศส T45 , T55
              สาเหตุหลักคือ แป้งของฝรั่งเศสเป็นแป้งที่ไม่ผ่านการฟอกสี แป้งจึงออกสีเหลืองอ่อนๆ
              เวลานำมาอบขนม จะได้เนื้อขนมที่เบา ฟู และได้กลิ่นหอมจากวัตถุดิบนั้นๆจริงๆ เนยเป็นเนย
              ซึ่งแป้งไทยจะกลิ่นแรงกว่าทำให้กลบกลิ่นวัตถุดิบจนหมด โดยเราใช้แป้ง T45 และ T55 ผสมกัน
              เพราะต้องการให้คุกกี้มีเนื้อที่นุ่มหนึบ (หากใช้ T55 ตัวเดียว คุกกี้จะกรอบ)
              ส่วนเรื่องของ’ความอร่อย’ อันนี้ขอกดข้าม เพราะคุณเท่านั้นที่บอกเราได้ค่ะ
              ต้องลองแล้วมั้ยนะ
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="orange lighten-2" icon @click="showChewyChocChip = !showChewyChocChip">
              <v-icon>{{ showChewyChocChip ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showChewyChocChip">
              <v-divider></v-divider>

              <v-card-text
                class="text-left brown--text"
                data-aos="fade-up"
                data-aos-easing="ease-in-sine"
              >
                <b>#เกร็ดความรู้ </b> แป้ง T45 and T55 เป็นชื่อเรียกแป้งสาลีของฝรั่งเศส ซึ่งเรียกตามค่า ash
                (mineral content) หรือแร่ธาตุต่างๆ (โซเดียม โปแตสเซียม แคลเซียม)
                ที่เหลืออยู่เมื่อนำแป้งไปเผาที่ความร้อน 600 C นาน 2 ชั่วโมง เช่น แป้ง T45(Type 45)
                คือแป้งสาลี 10 กก นำไปเผา แล้วเหลือ mineral content 45 มิลลิกรัม เป็นต้น
                ซึ่งเราจะสังเกตได้ว่า แป้งสาลีของต่างประเทศจะมีระบุ mineral content กับ ค่าโปรตีนไว้
                และให้เราเลือกใช้เองตามความเหมาะสม เพราะปริมาณโปรตีนส่งผลต่อโครงสร้างและเนื้อขนม
                เช่น หากเราต้องการทำเค้ก ก็ควรใช้แป้งที่มีค่าโปรตีนต่ำ (กลูเตนต่ำ)
                แต่หากเป็นพวกขนมปัง ก็ควรเลือกแป้งที่มีโปรตีนสูง (กลูเตนต่ำ)
                ซึ่งแตกต่างจากแป้งของไทย <br> ที่เรียกตามการใช้งาน เช่น แป้งขนมปัง, แป้งเค้ก, <br>
                แป้งเอนกประสงค์

                <div class="pt-3 grey--text" data-aos="fade-up" data-aos-easing="ease-in-sine">
                  18/07/2021
                </div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    showScone: false,
    showChewyChocChip: false
  })
}
</script>
<style scoped>
.background {
  background-color: #edede7;
}
</style>
